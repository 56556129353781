'use client'

import Image from "next/image";
import { useEffect, useState } from "react";

const ScrollToTop = () => {
    const [showButton, setShowButton] = useState(false);

    useEffect(() => {
        // Check scroll position on mount
        if (window.scrollY > 300) {
            setShowButton(true);
        }
        
        const handleScroll = () => {
            if (window.scrollY > 300) {
                setShowButton(true);
            } else {
                setShowButton(false);
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    return (
        <>
            {showButton && (
                <button
                    onClick={scrollToTop}
                    className="fixed bottom-8 right-8 z-50 w-12 h-12 bg-gradient-to-r from-[#6584FE] via-[#8E7DFD] to-[#807EFE] rounded-full flex items-center justify-center shadow-lg hover:bg-blue-700 transition duration-300"
                    aria-label="Scroll to top"
                >
                    <Image src={'/images/scroll-to-top.svg'} alt="icon" width={16} height={22}/>                    
                </button>
            )}
        </>
    )
}

export default ScrollToTop;