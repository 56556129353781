'use client'

import Link from "next/link";
import { usePathname } from 'next/navigation';
import { Suspense } from "react";

const Navbar = ({ isMobile = false, setShowMobileMenu }) => {
    const pathname = usePathname();
    const isActive = (path) => pathname === path;
    const activeClass = (path) => (isActive(path) ? "text-[#7C80FE]" : "");
    
    const handleMenuClick = () => {
        // Close the menu when an item is clicked
        setShowMobileMenu(false);
    };

    return (
        <>
            <Suspense fallback={null}>
                {
                    isMobile ? (
                        <div className="bg-[#FFFFFF] md:hidden">
                            <nav className="flex flex-col items-center text-base justify-center space-y-4 py-4 shadow-lg">
                                <Link onClick={handleMenuClick} href={'/'} replace={true} className={`hover:text-[#7C80FE] cursor-pointer font-semibold text-base ${activeClass('/')}`}>Trang chủ</Link>
                                <Link onClick={handleMenuClick} href={'/services'} replace={true} className={`hover:text-[#7C80FE] cursor-pointer font-semibold text-base ${activeClass('/services')}`}>Dịch vụ</Link>
                                <Link onClick={handleMenuClick} href={'/solutions'} replace={true} className={`hover:text-[#7C80FE] cursor-pointer font-semibold text-base ${activeClass('/solutions')}`}>Giải pháp</Link>
                                <Link onClick={handleMenuClick} href={'/equipment'} replace={true} className={`hover:text-[#7C80FE] cursor-pointer font-semibold text-base ${activeClass('/equipment')}`}>Thiết bị</Link>
                                <Link onClick={handleMenuClick} href={'/news'} replace={true} className={`hover:text-[#7C80FE] cursor-pointer font-semibold text-base ${activeClass('/news')}`}>Tin tức</Link>
                            </nav>
                        </div>
                    ) : (
                        <nav className="hidden md:flex md:ml-14 md:mr-auto flex-wrap items-center text-base justify-center">
                            <Link href={'/'} replace={true} className={`mr-6 hover:text-[#7C80FE] cursor-pointer font-semibold text-base ${activeClass('/')}`}>Trang chủ</Link>
                            <Link href={'/services'} replace={true} className={`mr-6 hover:text-[#7C80FE] cursor-pointer font-semibold text-base ${activeClass('/services')}`}>Dịch vụ</Link>
                            <Link href={'/solutions'} replace={true} className={`mr-6 hover:text-[#7C80FE] cursor-pointer font-semibold text-base ${activeClass('/solutions')}`}>Giải pháp</Link>
                            <Link href={'/equipment'} replace={true} className={`mr-6 hover:text-[#7C80FE] cursor-pointer font-semibold text-base ${activeClass('/equipment')}`}>Thiết bị</Link>
                            <Link href={'/news'} replace={true} className={`mr-6 hover:text-[#7C80FE] cursor-pointer font-semibold text-base ${activeClass('/news')}`}>Tin tức</Link>
                        </nav>
                    )}
            </Suspense>

        </>
    )
}
export default Navbar;