import(/* webpackMode: "eager" */ "/var/www/node_modules/keen-slider/keen-slider.min.css");
;
import(/* webpackMode: "eager" */ "/var/www/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/var/www/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/var/www/node_modules/next/font/google/target.css?{\"path\":\"src/app/fonts.jsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-inter\",\"display\":\"swap\"}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/src/components/site/header.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/src/components/site/scroll-to-top.jsx");
;
import(/* webpackMode: "eager" */ "/var/www/src/styles/globals.css");
