'use client'

import Image from "next/image";
import Link from "next/link";
import { useState } from "react";
import LinkGradient from "../link-button/link-gradient";
import Navbar from "./navbar";

export default function Header() {
    const [showMobileMenu, setShowMobileMenu] = useState(false);
    
    return (
        <header className="fixed top-0 z-50 w-full">
            {/* Top bar */}
            <div className="bg-[#F5F6F8] h-[44px]">
                <div className="h-full w-full container mx-auto flex items-center justify-between px-4">
                    <div className="flex flex-row gap-6">
                        {/* Phone Info */}
                        <div className="flex items-center justify-center gap-2">
                            <Image src={'/images/phone.svg'} alt="icon" width={24} height={24} priority={false} />
                            <a href="call:0902358926"><span className="text-[#02091C] text-sm">{'+84 902358926'}</span></a>
                        </div>
                        <div className="w-[1px] bg-[#E6E9EB] hidden md:block"></div>
                        {/* Working Hours */}
                        <div className="hidden md:flex items-center justify-center gap-2">
                            <Image src={'/images/calendar.svg'} alt="icon" width={24} height={24}/>
                            <span className="text-[#02091C] text-sm">{'Thứ 2 -> Thứ 7: 08:00 - 18:00'}</span>
                        </div>
                    </div>
                    <div className="flex flex-row gap-6">
                        {/* Social Links */}
                        <div className="flex items-center justify-center gap-4">
                            <a href={"#"}><Image src={'/images/tiktok.svg'} alt="icon" width={24} height={24}/></a>
                            <a href={"#"}><Image src={'/images/facebook.svg'} alt="icon" width={24} height={24}/></a>
                            <a href={"#"}><Image src={'/images/linkedin.svg'} alt="icon" width={24} height={24}/></a>
                            <a href={"#"}><Image src={'/images/youtube.svg'} alt="icon" width={24} height={24}/></a>
                        </div>
                        <div className="w-[1px] bg-[#E6E9EB] hidden md:block"></div>
                        {/* Language Selector */}
                        <div className="hidden md:flex items-center justify-center gap-2">
                            <span className="text-[#02091C] text-sm">{'Tiếng việt'}</span>
                            <Image src={'/images/arrow-down.svg'} alt="icon" width={18} height={18} priority={false}/>
                        </div>
                    </div>
                </div>
            </div>
            {/* Main Header */}
            <div className="bg-[#FFFFFF]">
                <div className="container mx-auto flex flex-wrap py-[14px] flex-row items-center justify-between px-4">
                    {/* Mobile Menu Button */}
                    <div className="md:hidden">
                        <button
                            onClick={() => setShowMobileMenu(!showMobileMenu)}
                            className="flex items-center px-3 py-2 border rounded text-gray-900 border-gray-400 hover:text-[#7C80FE] hover:border-[#7C80FE]"
                        >
                            <svg className="fill-current h-3 w-3" viewBox="0 0 20 20">
                                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
                            </svg>
                        </button>
                    </div>
                    {/* Logo */}
                    <Link href={'/'} rel="preload" className="flex title-font font-medium items-center text-gray-900">
                        <Image alt="logo"
                            src={'/images/logo.svg'}
                            width={170}
                            height={20}
                            priority={false}
                            quality={100}
                            className="w-44 h-auto object-cover" 
                        />
                    </Link>
                    {/* Navigation */}
                    <Navbar setShowMobileMenu={setShowMobileMenu}/>
                    {/* CTA Button */}
                    <LinkGradient title={'Hợp tác kinh doanh'} myClass="hidden md:flex" />

                    {/* Language Selector */}
                    <div className="md:hidden flex items-center justify-center gap-2">
                        <span className="text-[#02091C] text-sm">{'VI'}</span>
                        <Image src={'/images/arrow-down.svg'} alt="icon" width={18} height={18} priority={false} />
                    </div>
                </div>
            </div>
            {/* Mobile Menu */}
            {showMobileMenu && <Navbar isMobile={true} setShowMobileMenu={setShowMobileMenu} />}      
        </header>
    );
}
